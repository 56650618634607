import React from "react";

function Ticketing_Hour() {
  return (
    <div className="pt-5">
      <img
        src="../../images/Chart_Ticketing.png"
        alt="OODC_Logo"
        className="w-full"
      />
    </div>
  );
}

export default Ticketing_Hour;
