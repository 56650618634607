import React from "react";

function Telephony() {
  return (
    <>
      <div className="bg-red-100 w-full h-full">
        <img
          src="../../images/Telephony.png"
          alt=""
          className="w-full h-full object-cover"
        />
      </div>
    </>
  );
}

export default Telephony;
